import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
// 'react-redux'라이브러리에서 connect 변수 import. 
// 단 여기서 connect 변수는 export 시에 default로 하지 않았기 때문에(export default connect가 아니기 때문에) -> { } 가 붙는다
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';

import MainAppShell from './MainAppShell';
import MainAppShellField from './MainAppShellField';
import MainAppShellFieldApp from './MainAppShellFieldApp';

import Home from './Home';
import Home_Birth from './Home_Birth';
import Home_Fath from './Home_Fath';
import Membership from './Membership';
import DhlotterySite from './DhlotterySite';

import InfoAgreememnt from './InfoAgreememnt';
import kakaoAdfitPage from './kakaoadfitpage';

import HomeApp from './HomeApp';

//import './AppShellBox.scss'

// 'redux/actions/auth'안에 있는 모든 변수(*)의 값을 authActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
import * as authActions from 'redux/actions/auth'

// 'redux/actions/upload'안에 있는 모든 변수(*)의 값을 uploadActions 라는 변수로 바뀌어서 사용가능하게 import 한다는 뜻
// import * as uploadActions from 'redux/actions/upload'

import { createBrowserHistory } from 'history';

const styles = theme => ({
    
});

const customHistory = createBrowserHistory();
const prevHistoryPush = customHistory.push;
let lastLocation = customHistory.location;

customHistory.listen(location => {
  lastLocation = location;
});
customHistory.push = (pathname, state = {}) => {
  if (
    lastLocation === null ||
    pathname !==
      lastLocation.pathname + lastLocation.search + lastLocation.hash ||
    JSON.stringify(state) !== JSON.stringify(lastLocation.state)
  ) {
    prevHistoryPush(pathname, state);
  }
};


//----------------------------------------------------------------------------
// 이거 안씀. 그냥 참조 바람.
//----------------------------------------------------------------------------
/*
const UploadContainer = () => (
    <div>
      <Route exact path="/" render={() => <Redirect to="/upload" />} />
      <Route path="/upload" component={UploadPhoto} />
    </div>
  )
  
  
   const DefaultContainer = () => (
        <MainAppShell>
            <div>

            <Route exact path="/" component={Home} />
            <Route exact path="/design" component={Design} />
            <AppBarRoute exact path="/law" component={Law} />
            

            <Route exact path="/mypage" component={MyPage} />
            <Route exact path="/myaccount" component={MyAccount} />
            <Route exact path="/texts" component={Texts} />
            <Route exact path="/words" component={Words} />
            </div>
        </MainAppShell>
   )
*/
//----------------------------------------------------------------------------

/*
// 아래의 주석처리된것도 동작 됨. 단, 코드가 조금 더 길어서 다른것으로 사용했음.
const AppBarLayout = (props) => {
    return (
        <div>
            {props.appBar ? React.createElement(props.appBar) : null}
            {props.children}
        </div>
    );
};

const AppBarRoute = ({ component, ...routeProps }) => {
    return (
        <Route {...routeProps} render={(props) => {
            return (
                <MainAppShell>
                    <AppBarLayout { ...props} {...routeProps}>
                        {React.createElement(component, props)}
                    </AppBarLayout>
                </MainAppShell>
            );
        }} />
    );
};
*/

const AppBarRoute = ({exact, path, component: Component}) => (
    <Route exact={exact} path={path} render={(props) => (   
      <div>
        <MainAppShell>
            <Component {...props}/>
        </MainAppShell>
      </div>
    )}/>
)

const AppBarFieldRoute = ({exact, path, component: Component}) => (
    <Route exact={exact} path={path} render={(props) => (   
      <div>
        <MainAppShellField>
            <Component {...props}/>
        </MainAppShellField>
      </div>
    )}/>
)

const AppBarFieldRouteApp = ({exact, path, component: Component}) => (
  <Route exact={exact} path={path} render={(props) => (   
    <div>
      <MainAppShellFieldApp>
          <Component {...props}/>
      </MainAppShellFieldApp>
    </div>
  )}/>
)

// admin 페이지
const AppBarAdminRoute = ({exact, path, component: Component}) => (
  <Route exact={exact} path={path} render={(props) => (   
    <div>
      <MainAppShell_Admin>
          <Component {...props}/>
      </MainAppShell_Admin>
    </div>
  )}/>
)

// admin 페이지
const AppBarFieldAdminRoute = ({exact, path, component: Component}) => (
  <Route exact={exact} path={path} render={(props) => (   
    <div>
      <MainAppShellField_Admin>
          <Component {...props}/>
      </MainAppShellField_Admin>
    </div>
  )}/>
)

/*
프로세스 완성후 삭제
const AppBarProcessRoute = ({exact, path, component: Component}) => (
  <Route exact={exact} path={path} render={(props) => (   
    <div>
      <MainAppShellProcess>
          <Component {...props}/>
      </MainAppShellProcess>
    </div>
  )}/>
)
*/


class AppShellBox extends React.Component {
    render() {
        const { classes, theme, isuploadtbtn } = this.props;

        return (
            <div className={classes.root}>
                <Router history={customHistory}>
                    <Switch>
                        {/* 왼쪽 분야 클릭시 나오는 App bar */}
                        <AppBarFieldRoute exact path="/" component={Home} />
                        <AppBarFieldRoute exact path="/birth" component={Home_Birth} />
                        <AppBarFieldRoute exact path="/fath" component={Home_Fath} />
                        <AppBarFieldRoute exact path="/membership" component={Membership} />
                        <AppBarFieldRoute exact path="/dhlotterysite" component={DhlotterySite} />

                        <Route exact path="/infoagreememnt" component={InfoAgreememnt} />
                        <Route exact path="/kakaoadfitpage" component={kakaoAdfitPage} />

                        <AppBarFieldRouteApp exact path="/home" component={HomeApp} />
                    </Switch>
                </Router>
            </div>
        );
    }
}

// store 의 state 를 컴포넌트의 props 에 매핑 시켜준다.
const mapStateToProps = (state) => ({
    isuploadtbtn: state.upload.isuploadtbtn,    // 현재 안쓰고 있음. 나중에 관련 state 지우기 바람
    isKlaytnLoggedIn: state.auth.isKlaytnLoggedIn,
    // isAwsLoggedIn: state.auth.isAwsLoggedIn,
})

// 컴포넌트 안에서 특정 props 함수가 실행시 dispatch 변경 -> reducer 변경
const mapDispatchToProps = (dispatch) => ({
    integrateWallet: (privateKey) => dispatch(authActions.integrateWallet(privateKey)),
    removeWallet: () => dispatch(authActions.removeWallet()),
    // awsCognitoOn: (email, password) => dispatch(authActions.awsCognitoOn(email, password)),
  })

AppShellBox = withStyles(styles, {withTheme: true})(AppShellBox)
export default connect(mapStateToProps, mapDispatchToProps)(AppShellBox);