import {
    UPLOAD,
    NOTUPLOAD,
    UPLOADCONTENTS,
    COMMENTLOADINGTRUE,
    COMMENTLOADINGFALSE,
    FAVORITELOADINGTRUE,
    FAVORITELOADINGFALSE,
    COMMFAVORITELOADINGTRUE,
    COMMFAVORITELOADINGFALSE,
    FOLLOWINGLOADINGTRUE,
    FOLLOWINGLOADINGFALSE,
    APPLYINGLOADINGTRUE,
    APPLYINGLOADINGFALSE,
    UPLOADSEARCH,
    SEARCHTOGGLETRUE,
    SEARCHTOGGLEFALSE,
    SEARCHKEYWORDTRUE,
    SEARCHKEYWORDFALSE,
    DRAWERTOGGLETRUE,
    DRAWERTOGGLEFALSE,
    UPLOADCONNECTINGTRUE,
    UPLOADCONNECTINGFALSE,
    DEVICELISTLOADINGTRUE,
    DEVICELISTLOADINGFALSE,
    DEVICEHOMELOADINGTRUE,
    DEVICEHOMELOADINGFALSE,
    DEVICEDBCONNECTOADINGTRUE,
    DEVICEDBCONNECTLOADINGFALSE
  } from 'redux/actions/actionTypes'

  // 초기State값
const initialState = {
    isCommentLoading: null,
    searchKeyword: '',
    isSearchToggle: false,
    isSearchKeyword: false,
    isuploadtbtn: null,
    uploadContents: '',
    //isFollowingLoading: '',
    isApplyingLoading: '',
    isdrawerToggle: false,
    isUploadConnecting: false,
    isDeviceListLoading: false,
    isDeviceHomeLoading: false,
    isDeviceDBConnectLoading: false
  }
  
  // state = initialState 초기 상태 값
  // action을 취하면 
  // 새로움 state값으로 변경 => { }
  const uploadReducer = (state = initialState, action) => {
    switch (action.type) {
      case DEVICELISTLOADINGTRUE:
        return {
          ...state,
          isDeviceListLoading: true,
        }
        case DEVICELISTLOADINGFALSE:
        return {
          ...state,
          isDeviceListLoading: false,
        }
        case DEVICEHOMELOADINGTRUE:
        return {
          ...state,
          isDeviceHomeLoading: true,
        }
        case DEVICEHOMELOADINGFALSE:
        return {
          ...state,
          isDeviceHomeLoading: false,
        }
        case DEVICEDBCONNECTOADINGTRUE:
          return {
            ...state,
            isDeviceDBConnectLoading: true,
        }
        case DEVICEDBCONNECTLOADINGFALSE:
          return {
            ...state,
            isDeviceDBConnectLoading: false,
        }
        case COMMENTLOADINGTRUE:
        return {
          ...state,
          isCommentLoading: true,
        }
        case COMMENTLOADINGFALSE:
        return {
          ...state,
          isCommentLoading: false,
        }
        case FAVORITELOADINGTRUE:
        return {
          ...state,
          isFavoriteLoading: true,
        }
        case FAVORITELOADINGFALSE:
        return {
          ...state,
          isFavoriteLoading: false,
        }
        case COMMFAVORITELOADINGTRUE:
        return {
          ...state,
          isCommFavoriteLoading: true,
        }
        case COMMFAVORITELOADINGFALSE:
        return {
          ...state,
          isCommFavoriteLoading: false,
        }
        case FOLLOWINGLOADINGTRUE:
        return {
          ...state,
          isFollowingLoading: true,
        }
        case FOLLOWINGLOADINGFALSE:
        return {
          ...state,
          isFollowingLoading: false,
        }
        case APPLYINGLOADINGTRUE:
        return {
          ...state,
          isApplyingLoading: true,
        }
        case APPLYINGLOADINGFALSE:
        return {
          ...state,
          isApplyingLoading: false,
        }
        case UPLOADCONNECTINGTRUE:
          return {
            ...state,
            isUploadConnecting: true,
          }
        case UPLOADCONNECTINGFALSE:
          return {
            ...state,
            isUploadConnecting: false,
        }
        case UPLOADSEARCH:
        return {
          ...state,
          searchKeyword: action.payload.searchKeyword,
        }
        case SEARCHTOGGLETRUE:
        return {
          ...state,
          isSearchToggle: true,
        }
        case SEARCHTOGGLEFALSE:
        return {
          ...state,
          isSearchToggle: false,
        }
        case SEARCHKEYWORDTRUE:
        return {
          ...state,
          isSearchKeyword: true,
        }
        case SEARCHKEYWORDFALSE:
        return {
          ...state,
          isSearchKeyword: false,
        }
        case DRAWERTOGGLETRUE:
        return {
          ...state,
          isdrawerToggle: true,
        }
        case DRAWERTOGGLEFALSE:
        return {
          ...state,
          isdrawerToggle: false,
        }
        case UPLOAD:
        return {
          ...state,
          isuploadtbtn: true,
          uploadPage: action.payload.uploadPage,
        }
        case NOTUPLOAD:
        return {
          ...state,
          isuploadtbtn: false,
        }
        case UPLOADCONTENTS:
        return {
          ...state,
          uploadContents: action.payload.uploadContents,
        }
        default:
        return state
    }
  }
  
  export default uploadReducer